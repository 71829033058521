@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
*,
body,
html {
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.bannerimage {
  height: 290px;
  /* width: 720px; */
}
.bannershapeimage {
  background-repeat: no-repeat;
}
.clientbgimage {
  /* background-image: url();
    background-repeat: no-repeat; */
  /* background-image: url('assets/images/Section.svg') !important; */
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: top left;
}
.footerimages {
  background-color: #ee9106;
  padding: 10px;
  border-radius: 50%;
  text-align: center;
  outline: 6px solid rgba(238, 145, 6, 0.28);
  border: 6px solid #f4b456;
}
.boxShadow {
  border-radius: 0.833vw;
  /* border: .7px solid #e5e7eb; */
  background: #fff;
  box-shadow: 0 8px 20px 0 rgba(9, 14, 20, 0.2);
}
.boxShadow1 {
  border-radius: 0.833vw;

  box-shadow: 0 8px 20px 0 rgba(9, 14, 20, 0.2);
}
.boxShadow2 {
  /* border-radius: .833vw; */

  box-shadow: 0 8px 20px 0 rgba(9, 14, 20, 0.2);
}
.hamburger-menu {
  display: none;
}

.testimonialsboxShadow1 {
  border-radius: 0.833vw;
  box-shadow: 0px 0px 16px 1px rgb(9 14 20 / 7%);
}
@media screen and (max-width: 1024px) {
  .desktopmenu {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
  .hamburger-menu {
    position: relative;
  }

  .menu-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    text-align: end;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .bar {
    width: 100%;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
  }

  .menu-icon.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }

  .menu {
    max-width: 100%;
    width: 100%;
    text-align: center;
    position: absolute;
    height: screen;
    /* top: 50px; */
    /* right: 20px; */
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* display: none; */
  }

  .menu.open {
    display: block;
  }

  .menu ul {
    list-style-type: none;
    padding: 0;
  }

  .menu li {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  .menu li:last-child {
    border-bottom: none;
  }
}
